import React, { useContext, useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";
import { BiographyNavigation } from "../components/biography/biography-navigation";

const Page = styled.div`
  & h1,
  & h3 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    text-indent: 99px;

    @media (max-width: 768px) {
      text-indent: 35px;
    }
  }

  & p {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .section-title-container {
    margin: 0 0 30px 0;

    &.with-subsection {
      & h1,
      & h3 {
        line-height: 20px;
      }
    }
  }

  & section {
    margin: 0 0 60px 0;

    max-width: 870px;
  }

  & ol {
    & li {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      & p {
        margin: 0;
      }

      & h4 {
        margin: 0 15px 0 0;
      }
    }
  }

  & .exhibitions,
  & .group-exhibitions,
  & .projects,
  & .public-art,
  & .awards,
  & .collections {
    & ol {
      & li {
        display: block;
        margin: 0 0 30px 0;

        & p {
          margin: 0;
        }

        & h4 {
          margin: 0 0 0 0;
          text-indent: 99px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & h1 {
      text-indent: 35px;
    }

    & .exhibitions,
    & .group-exhibitions,
    & .projects,
    & .public-art,
    & .awards,
    & .collections,
    & .education,
    & .memberships {
      & ol {
        & li {
          display: block;
          margin: 0 0 30px 0;

          & p {
            margin: 0;
          }

          & h4 {
            margin: 0 0 0 0;
            text-indent: 35px;
          }
        }
      }
    }
  }
`;

const Cv = ({ data }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );

  const [activeCvTag, setActiveCvTag] = useState(`all`);

  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#ADE3D6`,
    });
  }, []);

  const education = data.prismicCv.data.education.map((item, index) => (
    <li key={`single_education_item_${index}`}>
      <h4>{item.year}</h4>
      <div
        dangerouslySetInnerHTML={{
          __html: item.education_location.html,
        }}
      />
    </li>
  ));

  const memberships = data.prismicCv.data.memberships.map((item, index) => (
    <li key={`single_membership_item_${index}`}>
      <h4>{item.year}</h4>
      <div
        dangerouslySetInnerHTML={{
          __html: item.membership_location.html,
        }}
      />
    </li>
  ));

  const exhibitions = data.prismicCv.data.all_exhibitions.map((item, index) => (
    <li key={`single_exhibition_item_${index}`}>
      <h4>{item.exhibition_year}</h4>
      <div
        dangerouslySetInnerHTML={{
          __html: item.exhibitions.html,
        }}
      />
    </li>
  ));

  const projects = data.prismicCv.data.all_curated_projects.map(
    (item, index) => (
      <li key={`single_project_item_${index}`}>
        <h4>{item.project_year}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: item.projects.html,
          }}
        />
      </li>
    )
  );

  const groupExhibitions = data.prismicCv.data.all_group_exhibitions.map(
    (item, index) => (
      <li key={`single_group_exhibition_item_${index}`}>
        <h4>{item.exhibition_year}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: item.exhibitions.html,
          }}
        />
      </li>
    )
  );

  const awards = data.prismicCv.data.awards.map((item, index) => (
    <li key={`single_award_item_${index}`}>
      <h4>{item.year}</h4>
      <div
        dangerouslySetInnerHTML={{
          __html: item.awards.html,
        }}
      />
    </li>
  ));

  const collections = data.prismicCv.data.collections.map((item, index) => (
    <li key={`single_collection_item_${index}`}>
      <h4>{item.year}</h4>
      <div
        dangerouslySetInnerHTML={{
          __html: item.collections.html,
        }}
      />
    </li>
  ));

  const publicWorks = data.prismicCv.data.all_public_works.map(
    (item, index) => (
      <li key={`single_works_item_${index}`}>
        <h4>{item.year}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: item.works.html,
          }}
        />
      </li>
    )
  );

  return (
    <>
      <PageSEO
        title={data.prismicCv.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicCv.url}`}
      />

      <Page>
        <BiographyNavigation
          bibliographyTags={false}
          cvTags={true}
          activeCvTag={activeCvTag}
          setActiveCvTag={setActiveCvTag}
        />

        {activeCvTag === `all` && (
          <section className="opening-text">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicCv.data.opening_text.html,
              }}
            />
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `education`) && (
          <section className="education" id="education">
            <div className="section-title-container">
              <h1>Education</h1>
            </div>
            <ol>{education}</ol>
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `memberships`) && (
          <section className="memberships" id="memberships">
            <div className="section-title-container">
              <h1>Memberships</h1>
            </div>
            <ol>{memberships}</ol>
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `exhibitions`) && (
          <section className="exhibitions" id="exhibitions">
            <div className="section-title-container with-subsection">
              <h1>Selected Solo Exhibitions</h1>
              <h3>* denotes an accompanying publication</h3>
            </div>
            <ol>{exhibitions}</ol>
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `group-exhibitions`) && (
          <section className="group-exhibitions" id="group-exhibitions">
            <div className="section-title-container with-subsection">
              <h1>Selected Group Exhibitions</h1>
              <h3>* denotes an accompanying publication</h3>
            </div>
            <ol>{groupExhibitions}</ol>
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `projects`) && (
          <section className="projects" id="projects">
            <div className="section-title-container">
              <h1>Curated Projects</h1>
            </div>
            <ol>{projects}</ol>
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `public-art`) && (
          <section className="public-art" id="public-art">
            <div className="section-title-container">
              <h1>Public Art</h1>
            </div>
            <ol>{publicWorks}</ol>
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `awards`) && (
          <section className="awards" id="awards">
            <div className="section-title-container">
              <h1>Awards and Residencies</h1>
            </div>
            <ol>{awards}</ol>
          </section>
        )}

        {(activeCvTag === `all` || activeCvTag === `collections`) && (
          <section className="collections" id="collections">
            <div className="section-title-container">
              <h1>Selected Public Collections</h1>
            </div>
            <ol>{collections}</ol>
          </section>
        )}
      </Page>
    </>
  );
};

export default withPreview(Cv);

export const query = graphql`
  {
    prismicCv {
      url
      data {
        title {
          text
        }
        opening_text {
          html
        }
        education {
          year
          education_location {
            html
          }
        }
        memberships {
          year
          membership_location {
            html
          }
        }
        all_exhibitions {
          exhibition_year
          exhibitions {
            html
          }
        }
        all_curated_projects {
          project_year
          projects {
            html
          }
        }
        all_group_exhibitions {
          exhibition_year
          exhibitions {
            html
          }
        }
        all_public_works {
          year
          works {
            html
          }
        }
        awards {
          year
          awards {
            html
          }
        }
        collections {
          year
          collections {
            html
          }
        }
      }
    }
  }
`;
