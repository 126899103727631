import React, { useEffect, useContext, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";
import { withUnpublishedPreview } from "gatsby-source-prismic";

// Pages
import Bibliography from "./bibliography";
import Biography from "./biography";
import Contact from "./contact";
import Cv from "./cv";
import Exhibitions from "./exhibitions";
import Index from "./index";
import News from "./news";
import Press from "./press";
import Splash from "./splash";

// Templates
import Artwork from "../templates/artwork";
import Exhibition from "../templates/exhibition";
import NewsArticle from "../templates/news-article";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

const Page = styled.div`
  margin: 30px 0 130px 0;

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }

  & .title-container {
    grid-column: 1 / 13;
    margin: 0 0 20px 0;

    & h1 {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: #163262;
    }
  }
`;

const NoResults = styled.div``;

const NotFoundPage = () => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );
  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#ECE5DF`,
    });
  }, []);

  return (
    <>
      <Helmet
        title={`Page Not Found`}
        meta={[
          {
            name: "og:title",
            content: `Page Not Found`,
          },
        ]}
      />
      <Page>
        <div className="title-container">
          <NoResults>
            <h1>Page not found</h1>
            <p>
              <Link to={`/`}>Back to homepage</Link>
            </p>
          </NoResults>
        </div>
      </Page>
    </>
  );
};

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    artwork: Artwork,
    bibliography: Bibliography,
    biography: Biography,
    contact: Contact,
    cv: Cv,
    exhibitions: Exhibitions,
    exhibition: Exhibition,
    homepage: Index,
    news_article: NewsArticle,
    news: News,
    press: Press,
    splash: Splash,
  },
});
